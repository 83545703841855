<template>
    <router-link class="user-item card shadow-sm rounded-xl mb-1"
                 ref="userItem"
                 active-class="activated"
                 v-bind:class="{ 'my-2': activated, 'shadow-lg': activated, 'approved': user.approved, 'pending': !user.approved, }"
                 v-bind:to="to">
        <div class="list-group-item list-group-item-action border-0">
            <div class="row no-gutters">
                <div class="col-lg-5">
                    <div class="d-flex align-items-center mb-2">
                        <h5 class="font-weight-bolder mb-0 mr-1">
                            {{ user.name }}
                        </h5>
                        <button class="btn btn-lg btn-status btn-outline-success border-0 p-1 py-0"
                                v-b-tooltip.html
                                title="User is verified."
                                placement="auto"
                                v-if="user.verified">
                            <font-awesome-icon icon="shield-check"></font-awesome-icon>
                        </button>
                        <button class="btn btn-lg btn-status btn-outline-success border-0 p-1 py-0"
                                v-b-tooltip.html
                                title="User is in required group(s)."
                                placement="auto"
                                v-if="inRequiredGroups">
                            <font-awesome-icon icon="comment-alt-check"></font-awesome-icon>
                        </button>
                        <button class="btn btn-lg btn-status btn-outline-danger border-0 p-1 py-0"
                                v-b-tooltip.html
                                title="User is not in required group(s)."
                                placement="auto"
                                v-else>
                            <font-awesome-icon icon="comment-alt-times"></font-awesome-icon>
                        </button>
                    </div>
                    <p class="mb-1">
                        +{{ user.countryCode }} {{ user.phone }}
                    </p>
                    <p class="mb-0">
                        Joined {{ timeCreated }}
                    </p>
                </div>
                <div class="col-lg-7 row no-gutters">
                    <!---<div class="col-6 col-md-4 col-lg">
                        <h2 class="text-center font-weight-bold mb-0">
                            TBA
                        </h2>
                        <p class="text-center mb-1">
                            Activities joined
                        </p>
                    </div> -->
                    <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                        <h2 class="text-center font-weight-bold mb-0">
                            {{ gender.charAt(0).toUpperCase() + gender.slice(1) }}
                        </h2>
                        <p class="text-center mb-1">
                            Gender
                        </p>
                    </div>
                    <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                        <div class="text-center"
                             v-if="isLoadingWhatsAppAccount">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <h2 class="text-center font-weight-bold mb-0"
                            v-else>
                            {{ chats.length }}
                        </h2>
                        <p class="text-center mb-1">
                            Chats
                        </p>
                    </div>
                    <div class="col col-md-4 col-lg d-flex flex-column justify-content-center">
                        <button class="btn btn-sm btn-block btn-success border-0 rounded-pill shadow-sm"
                                v-if="!isApproved"
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-on:click.stop.prevent="approve">
                            👍 Approve
                        </button>
                        <button class="btn btn-sm btn-block btn-warning border-0 rounded-pill shadow-sm"
                                v-else
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-on:click.stop.prevent="disapprove">
                            👎 Disapprove
                        </button>
                        <button class="btn btn-sm btn-block btn-light border rounded-pill shadow-sm"
                                v-if="isApproved"
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-on:click.stop.prevent="isModalGroupsOpen = true">
                            👪 Manage Group(s)
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <modal-groups v-bind:account="account"
                      v-model="isModalGroupsOpen">
        </modal-groups>
    </router-link>
</template>

<script>
import mongoObjectIdToDate from "@/utilities/mongoObjectIdToDate";
import { formatDistanceToNow, parseISO, } from "date-fns";
import { BSpinner, VBTooltip, } from "bootstrap-vue";
import ModalGroups from "./ModalGroups";
export default {
    name: "AdminItem",
    components: {
        ModalGroups,
        BSpinner,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    props: {
        "user": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
            isLoadingWhatsAppAccount: false,

            isModalGroupsOpen: false,
        };
    },
    computed: {
        to () {
            return `/users/admin/${ this.user._id }`;
        },
        email () {
            return this.$store.getters["admin/info"].email;
        },
        activated () {
            return this.user._id === this.$route.params.userId;
        },
        timeCreated () {
            const createdAt = this.user.createdAt ?? mongoObjectIdToDate(this.user._id);
            return formatDistanceToNow(parseISO(createdAt), { "addSuffix": true, });
        },
        isApproved () {
            return this.user.approved;
        },
        gender () {
            return this.user?.profile?.gender ?? "NA";
        },
        account () {
            if (this.user && this.user.countryCode && this.user.phone) {
                if (this.$store.state.whatsapp.accountsByUser[`${ this.user.countryCode }${ this.user.phone }`] === undefined) {
                    this.getAccountByUserId();
                }
                return this.$store.state.whatsapp.accountsByUser[`${ this.user.countryCode }${ this.user.phone }`];
            }
            return {};
        },
        chats () {
            return Array.isArray(this.account?.chats) ? this.account.chats : [];
        },
        inRequiredGroups () {
            return this.chats.reduce((acc, { tracking }) => acc || tracking, false);
        },
    },
    methods: {
        async getAccountByUserId () {
            if (!this.isLoadingWhatsAppAccount) {
                this.isLoadingWhatsAppAccount = true;
                await this.$store.dispatch(
                    "whatsapp/loadAccount",
                    `${ this.user.countryCode }${ this.user.phone }`
                );
                this.isLoadingWhatsAppAccount = false;
            }
        },
        async approve () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "users/editUser",
                    { "_id": this.user._id, "approved": true, }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },

        async disapprove () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "users/editUser",
                    { "_id": this.user._id, "approved": false, }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },

        async addToGroups () {
            this.isLoading = true;

            // open group modal
        },
    },
}
</script>

<style lang="scss" scoped>
.user-item {
    color: rgba(0, 0, 0, 1);
    text-decoration: none;

    transition:
        transform 350ms ease-in-out,
        margin 350ms ease-in-out,
        box-shadow 350ms ease-in-out;

    &.approved {
        border-left: solid 10px #28a745;
    }

    &.pending {
        border-left: solid 10px #ffc107;
    }

    &.activated {
        transform: scale(1.01);
        border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    }
}

.btn-status {
    line-height: 1;
}
</style>
